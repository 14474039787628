<template>
    <div class="user-phone-view-container">
        <div class="title-container">
            <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i><span class="view-name">更换手机号</span></div>
        </div>
        <div class="edit-container">
            <div class="authentication-view" v-show="authViewVisible">
                <div class="title">输入短信验证码</div>
                <div class="phone-num">验证码已发送至手机 +86 {{phoneNumberOld}}</div>
                <div class="auth-code">
                    <el-input class="input-code" placeholder="输入验证码" v-model="authCode1" clearable>
                    </el-input>
                </div>
                <div class="button-box">
                    <el-button class="btn-next" type="primary" size="medium" @click="onNext">下一步</el-button>
                </div>
                <div class="get-auth-code-box">
                    <el-link type="primary" :underline="false">重新获取验证码</el-link>
                    <div class="graphic-auth">(如果多次连续获取短信验证码，需要同时输入图形验证码)</div>
                </div>
            </div>
            <div class="phone-number-view" v-show="phoneViewVisible">
                <div class="title">输入新的手机号</div>
                <el-input class="phone-number" placeholder="输入手机号" maxlength="13" v-model="phoneNumText" clearable @input="onPhoneNumInput()">
                </el-input>
                <div class="auth-code-box">
                    <el-input class="input-auth-code" placeholder="输入验证码" v-model="authCode2" clearable></el-input>
                    <el-button class="btn-get-auth-code" type="primary" plain @click="onGetAuthCode">获取验证码</el-button>
                </div>
                <div class="button-box">
                    <el-button class="btn-ok" type="primary" @click="onSure">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '../../utils/store'

export default {
    name: 'UserPhoneEditView',
    data() {
        return {
            authViewVisible: true,
            phoneViewVisible: false,
            authCode1: "",
            phoneNumText: "",
            phoneNum: "",
            authCode2: ""
        }
    },
    computed: {
        phoneNumberOld: function() {
            return store.phoneNumber;
        }
    },
    created() {
        if (!store.phoneNumber) {
            this.authViewVisible = false;
            this.phoneViewVisible = true;
        }
    },
    methods: {
        closeView() {
            this.$emit("closeView");
            this.$router.go(-1);
        },
        onNext() {
            if ("" === this.authCode1) {
                this.$message.error("请输入短信验证码！");
                return;
            }
            this.authViewVisible = false;
            this.phoneViewVisible = true;
        },
        onPhoneNumInput() {
            if ("" == this.phoneNumText) {
                this.$message.error("请输入手机号！");
                return;
            }
            let inputText = this.phoneNumText.replace(/\s/g, "");
            if (!isNaN(inputText)) {
                this.phoneNum = inputText;
            }
            // console.log(this.phoneNumText, '=', this.phoneNum)
            let formatedText = "";
            if (this.phoneNum.length <= 3) {
                formatedText = this.phoneNum;
            } else {
                formatedText = this.phoneNum.substring(0, 3);
                if (this.phoneNum.length <= 7) {
                    formatedText += " " + this.phoneNum.substring(3);
                } else {
                    formatedText += " " + this.phoneNum.substring(3, 7);
                    formatedText += " " + this.phoneNum.substring(7);
                }
            }
            this.phoneNumText = formatedText;
        },
        onGetAuthCode() {

        },
        onSure() {
            if ("" == this.phoneNumText) {
                this.$message.error("请输入手机号！");
                return;
            }
            if ("" === this.authCode2) {
                this.$message.error("请输入短信验证码！");
                return;
            }
            this.$message.success("手机号更换成功。");
            this.$emit("closeView");
        }
    }
}
</script>

<style scoped>
    .user-phone-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;

        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 3;
    }
    .title-container {
        height: 12vmin;

        display: flex;
        align-items: stretch;
    }
    .title-container .back-icon {
        font-size: 6vmin;
        padding: 0 3vmin;

        display: flex;
        align-items: center;
    }
    .title-container .view-name {
        font-size: 4vmin;
        margin-left: 3vmin;
    }
    .title-container .back-icon:active {
        background-color: #eaeaea;
    }

    .edit-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 12vmin;
        bottom: 0;

        font-size: 4vmin;
        text-align: left;
        overflow-y: auto;
    }

    .authentication-view,
    .phone-number-view {
        padding: 1vmin 5vmin;
    }
    .authentication-view .title {
        font-size: 5vmin;
    }
    .authentication-view .phone-num {
        font-size: 3.5vmin;
        color: #aaa;
        margin-top: 2vmin;
    }
    .authentication-view .auth-code {
        margin-top: 3vmin;
    }
    .authentication-view .button-box {
        margin-top: 5vmin;
        text-align: center;
    }
    .authentication-view .get-auth-code-box {
        text-align: center;
        margin-top: 10vmin;
    }
    .authentication-view .graphic-auth {
        font-size: 2.5vmin;
    }
    .phone-number-view .phone-number {
        margin-top: 3vmin;
    }
    .phone-number-view .auth-code-box {
        margin-top: 3vmin;

        display: flex;
    }
    .phone-number-view .input-auth-code {
        margin-right: 1px;
    }
    .phone-number-view .button-box {
        margin-top: 10vmin;
        text-align: center;
    }
    .phone-number-view .btn-ok {
        width: 50vmin;
    }
</style>